// Load Styles
import '../scss/main.scss';

window.jQuery = $;
window.$ = $;

import slick from 'slick-carousel';

// Load Bootstrap init
import {initBootstrap} from "./bootstrap.js";



// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});


$('.center').not('.slick-initialized').slick({
  centerMode: true,
  centerPadding: '60px',
  slidesToShow: 3,
  variableWidth: true,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2500,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '10px',
        slidesToShow: 3
      }
    },
    {
      breakpoint: 480,

      settings: {
        arrows: true,
        centerMode: true,
        centerPadding: '5px',
        slidesToShow: 3
      }
    }
  ]
});




// Get the header
var header = document.getElementById("masthead");
var main = document.getElementById("main");

// Get the offset position of the navbar
var sticky = header.offsetTop;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
  if (window.pageYOffset > sticky) {
    header.classList.add("sticky");
    main.classList.add("margin-130");
  } else {
    header.classList.remove("sticky");
    main.classList.remove("margin-130");
  }
}

// When the user scrolls the page, execute myFunction
window.onscroll = function() {myFunction()};

//console.log(`Hello ${process.env.HELLO}`);
console.log('Welcome to GRIDL - The Cornish Breakfast Company | Breakfast in Newquay, Cornwall.')
